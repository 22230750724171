// import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'
const Experience = () => {
    // let navigate = useNavigate()

    return (
        <div className='experience-container' id="experience">
            <div className='experience'>
                <div className='numbered-heading'>Experience</div>
                <div className='jobs'>
                    <div className='job-container'>
                        <div className='job-description'>
                            <div className='year'>Jul 2018 - Aug 2020</div>
                            <h2>Web Developer & IT Technician <a href='https://ipsupply.com.au/' target='_blank' rel="noreferrer">IPSupply(Lidcombe)</a></h2>
                            <ul>
                                <li>Developed and managed the primary website and internal software systems.</li>
                                <li>Deployed and configured latest software while conducting testing of networking equipment from industry-leading providers such as Cisco, Dell, and Fortinet</li>
                                <li>Provided troubleshooting support and recommended new products to customers over the phone.</li>
                                <li>Training a team of five members</li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className='job-container'>
                        <div className='job-description'>
                            <div className='year'>Feb 2015 - Mar 2018</div>
                            <h2>Crew trainer <a href='https://mcdonalds.com.au/store/darling-quarter-nsw' target='_blank' rel="noreferrer">McDonald's(Haymarket)</a></h2>
                            <ul>
                                <li>Training new team members and providing guidance in day-to-day operations</li>
                                <li>Developed strong communication skills through effectively conveying instructions and providing constructive feedback</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Experience